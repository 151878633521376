import React from "react";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CertificateGrid = ({ certificates }) => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: theme.spacing(6) }}>
      <Grid container spacing={4} rowSpacing={6} justifyContent="center">
        {certificates.map((logo, index) => (
          <Grid item xs={6} sm={6} md={3} key={index}> {/* Responsive breakpoints added */}
            <Box
              sx={{
                width: "100%",
                height: { xs: "120px", md: "120px" }, // Adjusted height for mobile
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                overflow: "hidden",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <img
                src={logo}
                alt={`Certificate ${index + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CertificateGrid;
