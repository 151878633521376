import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { SidebarNav } from './components';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { NavItem, NavButton } from './components';
import AkygaLogo from "static/akyga.webp";
import AkygaDarkLogo from 'static/akyga.png';
import { useTranslation } from 'react-i18next';
import { getBasketFromLocalStorage } from 'Utilities/localStorage';

const Sidebar = ({ pages, open, variant, onClose }) => {

  const { t } = useTranslation();
  const [basket, setBasket] = useState(getBasketFromLocalStorage());
  const [highlight, setHighlight] = useState(false);
  useEffect(() => {
    const handleBasketChange = () => {
      setBasket(getBasketFromLocalStorage());
      setHighlight(true);
      setTimeout(() => setHighlight(false), 300);
    };

    window.addEventListener('basketChange', handleBasketChange);
    return () => window.removeEventListener('basketChange', handleBasketChange);
  }, []);

  const itemCount = Object.keys(basket).length;

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 1,
        }}
      >
        <SidebarNav pages={pages} />
        <Box
          sx={{
            height: '100%',
            padding: 1,
            paddingX: 3
          }}
        >
        <Badge badgeContent={itemCount} color="error">
            <Button
              variant="contained"
              color="primary"
              component="a"
              target="blank"
              href="/basket"
              size="medium"
              sx={{
                animation: highlight ? 'pulse 0.6s' : 'none',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.05)' },
                  '100%': { transform: 'scale(1)' },
                },
              }}
            >
              {t('topbar.getQuote')}
            </Button>
        </Badge>
        </Box>
      </Box>

    </Drawer>
  );
};

Sidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired,
};

export default Sidebar;
