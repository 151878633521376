//filesAPI.js

import axios from "axios";
import config from "../../config/config";

const getFilesApi = async (fileCategory) => {
  try {
    const response = await axios.get(`${config.API_BASE_URL}/files/${fileCategory}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching files:", error);
    throw error; 
  }
};

export default getFilesApi;
