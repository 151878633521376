import { useState, useEffect } from "react";
import getTableApi from '../API/tableAPI/groupAPI';

const useCategoryGroups = (targetGroupName, includeTrendingAndAll = true) => {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let groups = await getTableApi();
            setData(groups);

            const topLevelGroups = groups.filter(x => !x.parentId);
            const trendingGroupIds = groups.filter(x => x.isTopLevel).map(x => x.id);
            const targetGroup = groups.find(group => group.name === targetGroupName);
            let targetSubGroups = [];

            if (targetGroup) {
                const getSubGroups = (parentId) => {
                    const subGroups = groups.filter(group => group.parentId === parentId);
                    subGroups.forEach(subGroup => {
                        targetSubGroups.push(subGroup);
                        getSubGroups(subGroup.id); 
                    });
                };
                getSubGroups(targetGroup.id);
            }

            const zz = (targetGroup ? targetSubGroups : topLevelGroups).map(topGroup => ({
                label: topGroup.name,
                ids: groups
                    .filter(subGroup => subGroup.parentId === topGroup.id)
                    .map(subGroup => subGroup.id),
                hasChildren: groups.some(subGroup => subGroup.parentId === topGroup.id),
            }));

            const updatedFilters = [];

            if (includeTrendingAndAll) {
                updatedFilters.push(
                    { label: 'trending', ids: trendingGroupIds, hasChildren: true },
                    { label: 'all', ids: groups.filter(g => g.isLeaf).map(g => g.id), hasChildren: true }
                );
            }

            updatedFilters.push(...zz);

            setFilters(updatedFilters);
        }
        fetchData();
    }, [targetGroupName, includeTrendingAndAll]);

    return filters;
};


export default useCategoryGroups;
