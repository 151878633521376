import React, { useState } from "react";
import Main from "layouts/Main";
import { useTranslation } from "react-i18next";
import { Container, Box } from "@mui/material";
import { BatteryHero, FeaturedBatteryGroups, ContactLanding, TimelineComponent, CertificateGrid } from "./components";
import SEO from "components/SEO";

const LandingPage = () => {
    const { t } = useTranslation();

    return (
        <Main>
            <SEO 
                title={t("seo.home.title")}
                description={t("seo.home.description")}
                keywords={t("seo.home.keywords")}
                url="/"
            />
            <Box bgcolor={'alternate.main'}>
                <BatteryHero />
            </Box>
            <Container style={{marginBottom: '10vh'}}>
                <FeaturedBatteryGroups/>
            </Container>

            <Container>
                <TimelineComponent/>
            </Container>
            <Container>
                <CertificateGrid certificates={[
                    '/assets/c1.jpg',
                    '/assets/c2.jpg',
                    '/assets/c3.jpg',
                    '/assets/c4.jpg',
                    // '/assets/c5.jpg',
                    '/assets/c6.png',
                    '/assets/c7.png',
                    '/assets/c8.jpg',
                    '/assets/c9.jpg',
                ]}/>
            </Container>
            {/* <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="img"
                    src={'/assets/timeline.jpg'}
                    alt="Timeline"
                    sx={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </Container> */}
            <Container id="contact-section" style={{marginTop: '10vh', marginBottom: '10vh'}}>
                <ContactLanding />
            </Container>
        </Main>
    );
}

export default LandingPage;
