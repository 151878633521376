import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import useSendEmail from "Hooks/useSendContactUsEmail";
import SEO from "components/SEO";

import Form from "../../../ContactUs/components/Form";

const ContactLanding = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sendEmail, isLoading, isSuccess, error } = useSendEmail();
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: '',
  });

  const isFormFilled = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.lastName.trim() !== '' &&
      formData.email.trim() !== ''
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  const handleRequestQuote = async () => {
    const formFilled = isFormFilled();
    if (formFilled) {
      try {
        await sendEmail(formData);
        setOpen(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          message: '',
        });
      } catch (error) {
        console.error('Error sending email:', error);
      }
    } else {
      alert('Please fill out all required fields before requesting a quote.');
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "5vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(4),
      }}
    >
      <SEO 
        title={t("seo.contact.title")}
        description={t("seo.contact.description")}
        keywords={t("seo.contact.keywords")}
        url="/contact"
      />
      <Box
        component="img"
        src={'/assets/contact_bateryjka.png'}
        sx={{
          display: {
            xs: 'none',
            sm : 'none',
            lg: 'block'
         },
          position: "absolute",
          right: '-10%', 
          top: "100%", 
          width: "25%",
          transform: "rotate(0deg) translateY(-50%)", 
          backgroundImage: '/assets/conctact_bateryjka.png',
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <Box>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            marginBottom: theme.spacing(4),
          }}
        >
          {t("contactLanding.getInTouch")}
        </Typography>
        <Box>
          <Form
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleRequestQuote}
            disabled={isLoading}
          />
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%", marginTop: "60px" }}>
          Request has been sent!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactLanding;
