/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import { motion } from 'framer-motion';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Language';
import useDistributorsData from 'Hooks/useDistributorsData';

const FeaturesWithCardRepresentation = () => {
  const theme = useTheme();
  const distributors = useDistributorsData();

  const distributorsByCountry = distributors?.data?.reduce((acc, distributor) => {
    if (!acc[distributor.country]) {
      acc[distributor.country] = [];
    }
    acc[distributor.country].push(distributor);
    return acc;
  }, {}) || {};

  const sortedCountries = Object.entries(distributorsByCountry).sort((a, b) => b[1].length - a[1].length);

  return (
    <Box>
      <Container>
        {sortedCountries.map(([country, distributors], countryIndex) => (
          <Box key={countryIndex} marginBottom={4}>
            <Typography variant="h4" gutterBottom>
              {country}
            </Typography>
            <Grid container spacing={4}>
              {distributors.map((item, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: i * 0.2 }}
                  >
                    <Box component={Card} padding={4} width={1} height={1} bgcolor={'alternate.main'} boxShadow={2} borderRadius={2}>
                      <Box display={'flex'} flexDirection={'column'} height={1}>
                        <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                          {item.company}
                        </Typography>
                        <Typography color="text.secondary">
                          {item.address} {item.city}
                        </Typography>
                        {item.phone && (
                          <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <PhoneIcon sx={{ mr: 1, fontSize: '1.1rem' }} /> {item.phone}
                          </Typography>
                        )}
                        {item.email && (
                          <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <EmailIcon sx={{ mr: 1, fontSize: '1.1rem' }} /> 
                            <a 
                              href={`mailto:${item.email}`} 
                              style={{ 
                                color: theme.palette.primary.main,
                                textDecoration: 'underline',
                                '&:hover': {
                                  color: theme.palette.primary.dark
                                }
                              }}
                            >
                              {item.email}
                            </a>
                          </Typography>
                        )}
                        {item.website && (
                          <Typography color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <WebIcon sx={{ mr: 1, fontSize: '1.1rem' }} /> 
                            <a 
                              href={item.website.startsWith('http') ? item.website : `https://${item.website}`} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              style={{ 
                                color: theme.palette.primary.main,
                                textDecoration: 'underline',
                                '&:hover': {
                                  color: theme.palette.primary.dark
                                }
                              }}
                            >
                              {item.website}
                            </a>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default FeaturesWithCardRepresentation;