import { useState, useEffect } from 'react';

import getTileApi from '../API/tableAPI/tileAPI';
 
const useCategoryDetails = (categoryName) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getTileApi(categoryName);
            setData(response);
        }   
        fetchData(); 

    }, [categoryName]);

    return data;

};

export default useCategoryDetails;