// UberFilterGroup.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterPopover from './FilterPopover';

const UberFilterGroup = ({ filteredData, filterGroup, selectedFilters, callback }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const renderFilterName = (name) => {
    return name.split(/(<sub>.*?<\/sub>)/g).map((part, index) => {
      if (part.startsWith("<sub>") && part.endsWith("</sub>")) {
        return <sub key={index}>{part.replace(/<\/?sub>/g, "")}</sub>;
      }
      return part;
    });
  };

  useEffect(() => {
    const existingFilterValues = {};
    selectedFilters.forEach((filter) => {
      const control = filterGroup.controls.find(
        (control) => control.column === filter.columnName.split('.').pop()
      );
      if (control) {
        const multipler = control.multipler ?? 1;
        const unmultipliedValue = filter.value / multipler;
  
        if (control.filterType === 'minmax') {
          if (!existingFilterValues[control.column]) {
            existingFilterValues[control.column] = {};
          }
          if (filter.operator === 'gte') {
            existingFilterValues[control.column].min = unmultipliedValue;
          } else if (filter.operator === 'lte') {
            existingFilterValues[control.column].max = unmultipliedValue;
          }
        } else if (control.filterType === 'checkboxlist') {
          // Do not apply multipler for checkbox values
          existingFilterValues[control.column] = filter.value;
        } else {
          existingFilterValues[control.column] = Array.isArray(filter.value)
            ? filter.value.map((v) => v / multipler)
            : unmultipliedValue;
        }
      }
    });
    setFilterValues(existingFilterValues);
  }, [selectedFilters, filterGroup]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleFilterChange = (name, value) => {
    setFilterValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const buildRequest = (filters) => {
    const request = [];

    Object.entries(filters).forEach(([key, value]) => {
      const control = filterGroup.controls.find(
        (control) => control.column === key.split(' ')[1] || control.column === key
      );

      if (control) {
        const columnName = `Parameters.${control.column}`;

        let operator = "eq";

        // const multiplier = control.multipler ?? 1;
        // value = Array.isArray(value) ? value.map(v => v * multiplier) : value * multiplier;
        if (control.filterType === 'checkboxlist') {
          let columnName = control.column;
          let operator = "in";
          // todo: dzieja sie tu chore jazdy
          if (columnName === "label") {
            columnName = "Certificates";
            operator = "contains";
          } 
          let aa = `ParametersUI.${columnName}`;
          
          request.push({
            columnName: aa,
            value: value,
            operator: operator
          });
        } else if (control.filterType === 'minmax') {
          if (key.startsWith('min')) {
            const multipler = control.multipler ?? 1;
            const newValue = Array.isArray(value)
              ? value.map((v) => v * multipler)
              : value * multipler;
            request.push({
              columnName,
              value: newValue,
              operator: 'gte',
            });
          } else if (key.startsWith('max')) {
            const multipler = control.multipler ?? 1;
            const newValue = Array.isArray(value)
              ? value.map((v) => v * multipler)
              : value * multipler;
            request.push({
              columnName,
              value: newValue,
              operator: 'lte',
            });
          }
        } else {
          const multipler = control.multipler ?? 1;
          const newValue = Array.isArray(value)
            ? value.map((v) => v * multipler)
            : value * multipler;
          request.push({
            columnName,
            value: newValue,
            operator,
          });
        }
      }
    });

    return request;
  };
  
  const handleApply = () => {
    const request = buildRequest(filterValues);
    callback && callback(request);
    handleClose();
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        border={`1px solid ${theme.palette.divider}`}
        borderRadius={2}
        paddingY={1}
        paddingX={2}
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Typography>{renderFilterName(filterGroup.name)}</Typography>
        <ExpandMoreIcon
          sx={{
            marginLeft: 0.5,
            width: 16,
            height: 16,
            transform: open ? 'rotate(180deg)' : 'none',
          }}
        />
      </Box>
      <FilterPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        filterGroup={filterGroup}
        onFilterChange={handleFilterChange}
        onApply={handleApply}
        filteredData={filteredData}
        filterValues={filterValues} 
      />
    </Box>
  );
};

export default UberFilterGroup;
