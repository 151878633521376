import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const CategoryTile = ({ category }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const childrenIdsString = category.Children?.map((child) => child.id).join(",") ||
    category.childrenIds?.map((child) => child.id).join(",") || "";

  const goDeeper = () => {
    if (category.isLeaf) {
      navigate(`/products/${category.name}/list`);
    } else if (childrenIdsString) {
      navigate(`/products/${childrenIdsString}`);
    };
  };

  // Framer-motion animation variants
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Grid
      key={category.id}
      item
      xs={12}
      sm={6}
      md={3}
      component={motion.div}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      animate="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <Box>
        <Box
          onClick={goDeeper}
          style={{
            cursor: "pointer",
            borderRadius: "8px",
            overflow: "hidden", // This ensures the image doesn't overflow the tile
            background: theme.palette.background.level2,
            boxShadow: theme.shadows[2],
            transition: "transform 0.3s ease-in-out",
            height: "220px",
            position: "relative",
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
          onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <Box
              onClick={goDeeper}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "8px",
                overflow: "hidden",
                background: theme.palette.background.level2,
                boxShadow: theme.shadows[2],
                height: "220px",
                position: "relative",
              }}
              onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <img
                loading="lazy"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                src={category.imageSrc ? category.imageSrc : "/assets/niezdjecie.png"}
                alt={category.name}
              />
            </Box>

                </Box>
        <Box marginTop={1}>
          <Typography
            color={theme.palette.text.primary}
            fontWeight={700}
            variant={"h6"}
          >
            {category.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default CategoryTile;
