// useDistributorsData.js

import { useState, useEffect } from "react";
import getFilesApi from '../API/filesAPI/fileAPI';
import axios from "axios";
import config from "config/config";


const useDistributorsData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const distributorsData = await axios.get(`${config.API_BASE_URL}/distributors`);
        setData(distributorsData);
      } catch (error) {
        console.error("Error fetching certificate data:", error);
      }
    }

    fetchData();
  }, []); 

  return data;
};

export default useDistributorsData;
