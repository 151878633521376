import axios from "axios";
import { toAkygaCode } from "Utilities/categoryMapper";
import config from '../../config/config';


const getTableApi = async (categoryName) => {

    if (categoryName) {
      const response = await axios.get(`${config.API_BASE_URL}/group/${categoryName}`);
      return response.data;
    }
  
    return null;
};

export default getTableApi;

