import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO = ({ 
  title, 
  description, 
  keywords, 
  image, 
  url,
  type = 'website'
}) => {
  const { t } = useTranslation();
  const siteUrl = 'https://akygabattery.com';
  const defaultTitle = t('seo.defaultTitle');
  const defaultDescription = t('seo.defaultDescription');
  const defaultKeywords = t('seo.defaultKeywords');
  const defaultImage = '/assets/logo.png';

  const pageTitle = title ? `${title} | ${defaultTitle}` : defaultTitle;
  const pageDescription = description || defaultDescription;
  const pageKeywords = keywords || defaultKeywords;
  const pageImage = image || defaultImage;
  const pageUrl = url ? `${siteUrl}${url}` : siteUrl;

  // Breadcrumb structured data
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": siteUrl
    }]
  };

  // Add current page to breadcrumb if not home
  if (url && url !== '/') {
    breadcrumbData.itemListElement.push({
      "@type": "ListItem",
      "position": 2,
      "name": title || defaultTitle,
      "item": pageUrl
    });
  }

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={pageImage} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content="Akyga Battery" />
      <meta property="og:locale" content="en_US" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={pageImage} />
      <meta name="twitter:site" content="@akygabattery" />
      
      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow, max-image-preview:large" />
      <meta name="language" content="English" />
      <meta name="author" content="Akyga Battery" />
      <meta name="revisit-after" content="7 days" />
      <meta name="generator" content="React" />
      <link rel="canonical" href={pageUrl} />
      
      {/* Organization Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Akyga Battery",
          "url": siteUrl,
          "logo": pageImage,
          "description": pageDescription,
          "sameAs": [
            "https://www.facebook.com/akygabattery",
            "https://www.linkedin.com/company/akygabattery"
          ],
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "PL"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48-XXX-XXX-XXX",
            "contactType": "customer service",
            "areaServed": "Worldwide"
          }
        })}
      </script>

      {/* Breadcrumb Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbData)}
      </script>

      {/* WebPage Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": pageTitle,
          "description": pageDescription,
          "url": pageUrl,
          "isPartOf": {
            "@type": "WebSite",
            "name": "Akyga Battery",
            "url": siteUrl
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEO; 