import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const TimelineComponent = () => {
  const theme = useTheme();

  const timelineData = [
    { year: '2024', description: 'Wide range of energy storage systems for green technologies' },
    { year: '2020', description: 'Over 3,000 products in the assortment (primary batteries and rechargeable cells)' },
    { year: '2015', description: 'New business location in Europe due to numerous large international projects' },
    { year: '2011', description: 'Expansion of our batteries for the extended temperature range' },
    { year: '2008', description: 'Development of Lithium Iron Phosphate (LiFePO4) cells' },
    { year: '2005', description: 'Leading Supplier of Lithium-Ion Polymer (LiPo) batteries in the EU' },
    { year: '2001', description: 'Introduction of Lithium-Ion batteries for consumer electronics' },
  ];

  const variants = {
    hidden: { opacity: 0, x: -30 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <Box
      component={motion.div}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      variants={variants}
      padding={3}
    >
      <Typography variant="h3" color="primary" align="left" gutterBottom>
        Discover Akyga Battery
      </Typography>
      <Typography variant="body1" color="textSecondary" align="left" paragraph>
        Founded in 2001, Akyga brand specializes in providing high quality products. We are dedicated to innovation, ensuring our products meet the highest standards.
      </Typography>

      <Timeline
        // sx={{
        //   paddingRight: {
        //     xs: 20,  
        //     sm: 20,  
        //     lg: 50, 
        //   }
        // }}
      >
        {timelineData.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              {/* rzeczy z drugiej strony */}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                color="primary"
                sx={{
                  width: 30,
                  height: 30,
                  position: 'relative', 
                  zIndex: 2,
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: theme.palette.primary.main,
                    opacity: 0.3, 
                    transform: 'translate(-50%, -50%) scale(1)',
                    animation: 'pulse 1.5s infinite', 
                    zIndex: 1, 
                  },
                }}
              />
              {index < timelineData.length - 1 && 
                <TimelineConnector 
                  sx={{ height: {
                      lg: 150, 
                    } }} 
                />}
            </TimelineSeparator>
            <TimelineContent>
              {/* Make year bold and color match the primary color */}
              <Typography variant="h2" color="primary" fontWeight="bold">
                {item.year}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {item.description}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      {/* Add the pulse animation keyframes to the style */}
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: translate(-50%, -50%) scale(1);
              opacity: 0.3;
            }
            50% {
              transform: translate(-50%, -50%) scale(1.4);
              opacity: 0.6;
            }
            100% {
              transform: translate(-50%, -50%) scale(1);
              opacity: 0.3;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TimelineComponent;
