import { useState } from "react";
import axios from "axios";
import config from "config/config";

const useSendContactUsEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [error, setError] = useState(null);


  const formatFormDataToHTML = (formData) => {
    return `
      <p><strong>First Name:</strong> ${formData.firstName}</p>
      <p><strong>Last Name:</strong> ${formData.lastName}</p>
      <p><strong>Email:</strong> ${formData.email}</p>
      <p><strong>Company:</strong> ${formData.company}</p>
      <p><strong>Message:</strong> ${formData.message}</p>
    `;
  };

  const sendEmail = async (formData) => {
    setIsLoading(true);
    setIsSuccess(null);
    setError(null);

    const formHTML = formatFormDataToHTML(formData);

    try {
      const response = await axios.post(`${config.API_BASE_URL}/send-email`, {
        ...formData,
        html: formHTML,
        subject: formData.company ? `Contact form / Akyga battery x ${formData.company}` : 'Contact form / Akyga battery',
        email: formData.email,
        supportEmail: "sales@akygabattery.com"
      });
      setIsSuccess(true);
    } catch (err) {
      setIsSuccess(false);
      setError(err);
      console.error('There was an error sending the email:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return { sendEmail, isLoading, isSuccess, error };
};

export default useSendContactUsEmail;
