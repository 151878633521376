import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Container from 'components/Container';
import Main from "layouts/Main";
import CategoryTile from 'components/CategoryTile';
import SEO from "components/SEO";

import config from '../../config/config';

const CataloguePage = () => {
    const [categories, setCategories] = useState([]);
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();


    // TODO: Move this to the API and get a useTiles hook or something... 
    const fetchCategories = async (idsString) => {
        const endpoint = idsString ? `${config.API_BASE_URL}/tiles/${idsString}` : `${config.API_BASE_URL}/tiles`;
        try {
            const response = await fetch(endpoint);
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            } else {
                console.error('Failed to fetch categories:', response.statusText);
                setCategories([]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    useEffect(() => {
        fetchCategories(params.id);
    }, [params.id]);

    const renderCategories = () => {
        return categories.map(category => <CategoryTile key={category.id} category={category} />);
    };

    return (
        <Main>
            <SEO 
                title={t("seo.products.title")}
                description={t("seo.products.description")}
                keywords={t("seo.products.keywords")}
                url="/products"
            />
            <Box>
                <Container>
                    <Box marginBottom={2}>
                        <Typography variant="h4" color="text.primary" sx={{ fontWeight: 700 }}>
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                                sx={{
                                    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                    backgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                Products
                            </Typography>
                        </Typography>
                    </Box>
                    <Grid container spacing={{ xs: 2, md: 4 }}>
                        {renderCategories()}
                    </Grid>
                </Container>
            </Box>
        </Main>
    );
};

// const CategoryTile = ({ category }) => {
//     const theme = useTheme();
//     const navigate = useNavigate();

//     // Assuming 'Children' are part of the category data
//     let childrenIdsString = category.Children?.map(child => child.id).join(',');

//     const goDeeper = () => {
//         if (childrenIdsString) {
//             navigate(`/products/${childrenIdsString}`);
//         } else if (category.isLeaf) {
//             navigate(`/products/${category.name}/list`);
//         }
//     };

//     return (
//         <Grid item xs={12} sm={6} md={4}>
//             <Box
//                 onClick={goDeeper}
//                 style={{
//                     cursor: "pointer",
//                     borderRadius: "8px",
//                     background: theme.palette.background.level1,
//                     boxShadow: theme.shadows[2],
//                     padding: '4%',
//                     transition: 'transform 0.15s ease-in-out',
//                 }}
//                 width={1} 
//                 height={1} 
//                 position={'relative'}
//                 onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
//                 onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
//             >
//                 <img
//                     loading="lazy"
//                     style={{
//                         filter: 'brightness(0.7)',
//                         borderRadius: 8,
//                         width: '100%',
//                         height: '100%',
//                         objectFit: 'cover',
//                     }}
//                     src={category.imageSrc} 
//                 />
//                 <Box
//                     position={'absolute'}
//                     bottom={0}
//                     left={0}
//                     right={0}
//                     width={1}
//                     padding={2}
//                     zIndex={2}
//                     style={{
//                         backgroundColor: 'rgba(255, 255, 255, 0.5)',
//                         borderBottomLeftRadius: '8px',
//                         borderBottomRightRadius: '8px',
//                     }}
//                 >
//                     <Typography
//                         color={theme.palette.text.primary}
//                         fontWeight={700}
//                         variant={'h5'}
//                         noWrap
//                     >
//                         {category.name}
//                     </Typography>

//                 </Box>
//             </Box>
//         </Grid>
//     );
// }
export default CataloguePage;
