import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { useTranslation } from 'react-i18next';

const QuoteSent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          minHeight={'70vh'} 
          paddingTop={'50px'}
        >
          <Box>
            <Typography
              variant="h2"
              component={'h2'}
              sx={{ fontWeight: 700, mb: 2 }}
            >
              {t('quoteSent.title')}
            </Typography>
            <Typography variant="body1" color="text.secondary" mb={4}>
              {t('quoteSent.message')}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t('quoteSent.inTheMeantime')}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href={'/'}
                >
                  {t('quoteSent.buttonBackHome')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  href={'/products'}
                >
                  {t('quoteSent.buttonBrowseCatalogue')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  href={'/#about-us'}
                >
                  {t('quoteSent.buttonLearnAboutUs')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Main>
  );
};

export default QuoteSent;
