import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { useTranslation } from 'react-i18next';

const Section = ({ title, content }) => (
  <Box mb={4}>
    <Typography variant="h6" component="h2" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body1" paragraph>
      {content}
    </Typography>
  </Box>
);

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            {t('privacyPolicy.title')}
          </Typography>
          <Typography variant="body1" paragraph mb={4}>
            {t('privacyPolicy.subText')}
          </Typography>
          <Section 
            title={t('privacyPolicy.consent')}
            content={t('privacyPolicy.consentSubText')}
          />
          <Section 
            title={t('privacyPolicy.informationWeCollect')}
            content={t('privacyPolicy.informationWeCollectSubText')}
          />
          <Section 
            title={t('privacyPolicy.howWeUseYourInformation')}
            content={t('privacyPolicy.howWeUseYourInformationSubText')}
          />
          <Section 
            title={t('privacyPolicy.logFiles')}
            content={t('privacyPolicy.logFilesSubText')}
          />
          <Section 
            title={t('privacyPolicy.cookiesAndWebBeacons')}
            content={t('privacyPolicy.cookiesAndWebBeaconsSubText')}
          />
          <Section 
            title={t('privacyPolicy.advertisingPartnersPrivacyPolicies')}
            content={t('privacyPolicy.advertisingPartnersPrivacyPoliciesSubText')}
          />
          <Section 
            title={t('privacyPolicy.thirdPartyPrivacyPolicies')}
            content={t('privacyPolicy.thirdPartyPrivacyPoliciesSubText')}
          />
          <Section 
            title={t('privacyPolicy.ccpaPrivacyRights')}
            content={t('privacyPolicy.ccpaPrivacyRightsSubText')}
          />
          <Section 
            title={t('privacyPolicy.gdprDataProtectionRights')}
            content={t('privacyPolicy.gdprDataProtectionRightsSubText')}
          />
          <Section 
            title={t('privacyPolicy.childrensInformation')}
            content={t('privacyPolicy.childrensInformationSubText')}
          />
        </Box>
      </Container>
    </Main>
  );
};

export default PrivacyPolicy;
